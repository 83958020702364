import React from "react"
import "resources/style/style.less"
import Seo from "../components/seo"
import Layout from "components/layout"

const IndexPage = () => {
  return (
    <>
      <Seo title={"Wispay FAQ"} />
      <Layout headerVariant="black" headerBackground="blue"></Layout>
    </>
  )
}

export default IndexPage
